// WRITE ARTICLE
export const howToNameCompanies = {
  order: 6,
  name: "How to name companies",
  subcategory: "Companies & contacts",
  markdown: `# How to name companies

  Tower Hunt intends to help build an accurate, easily searchable list of commercial real estate companies that avoids duplicate entries. This helps provide maximum context when companies are connected to properties, investments, and contacts. When you {% inlineRouterLink %}add a new company{% /inlineRouterLink %}, please consider the following:

  ## Challenges

  - There are tens of thousands of companies in the industry.
  - Even within a certain geographic area, some companies have similar names.
  - Companies use legal entities to manage risk. These entities sometimes have vague and/or confusing names.
  - Companies operate under various aliases tied to brands, geographies, programmatic joint ventures, stock ticker symbols, different business lines, and acquired companies.
  - Companies go out of business
  - Well-known industry contacts start new companies

  ## Suggestions

  - Propose a name that industry practitioners associate with the company.
  - Propose a spoken name (ex. "Tower Hunt") instead of a legal name (ex. "Tower Hunt, Inc", "Tower Hunt, LLC").
  - Be curious about a company's independence: avoid proposing companies that roll up under other companies.
  - Some entities trace back to one-off actors that will not repeatedly transact. This is often the case with development sites. Instead of entering these entities as *companies*, try tracking them in {% inlineRouterLink articleId="stay-organized-with-tasks" %}tasks{% /inlineRouterLink %} and/or {% inlineRouterLink %}notes{% /inlineRouterLink %}.`,
};
